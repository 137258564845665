<template>
  <div class="yj">
    <topnavigation class="header"></topnavigation>
    <div class="content">
      <div class="first"></div>
      <div class="sencend">
        <div class="minbox">
          <div class="sen-top">
            <div class="title">
              <img src="../../assets/yj/twowhite2.png" alt="" />
              <div>应急指挥平台</div>
              <img src="../../assets/yj/twowhite1.png" alt="" />
            </div>
            <div style="text-indent: 2em" class="word">
              杭州尤拉夫科技有限公司依托积累的应急行业业务知识和数据架构经验，通过知识图谱、神经网络、全息档案和标签魔方等数据智能应用
              的构建，全面支撑智能化应用，围绕安全生产监管和自然灾害监测预警等业务，构建场景化、智能化、精准化和高时效的智能化分析服务能力
              以智能增效能，实现数据价值全景呈现
            </div>
          </div>
          <div
            style="
              font-size: 32px;
              font-weight: bold;
              margin: 79px 0 41px 0;
              color: #222222;
              text-align: left;
            "
          >
            合作案例
          </div>
          <div class="sen-bom">
            <div class="row">
              <div class="swiper-container">
                <div class="swiper-wrapper" style="width: 820px">
                  <div class="swiperItem swiper-slide" >
                    <p>金华市数智应急指挥平台</p>
                    <img src="../../assets/yj/jh.png" alt="" />
                  </div>
                  <div class="swiperItem swiper-slide" >
                    <p>永康市数智应急大脑</p>
                    <img src="../../assets/yj/yk.png" alt="" />
                  </div>
                  <div class="swiperItem swiper-slide" >
                    <p>天津市应急指挥管理平台</p>
                    <img src="../../assets/yj/tj.png" alt="" />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="threeth">
        <div class="title">
          <img src="../../assets/hyy/twobule1.png" alt="" />
          <div>应急指挥平台的五大特点</div>
          <img src="../../assets/hyy/twobule2.png" alt="" />
        </div>
        <div class="list-box">
          <div class="top">
            <div
              class="three-item"
              :class="activenum == '0' ? 'active' : ''"
              @mouseenter="mouseEnterNav('0', $event)"
            >
              <p>多终端融合通信</p>
              <div>
                拥有强大的跨平台能力，具备强大的对接兼容性， 可融合多种通
                讯手段，对各种通讯手段实现灵活的调度管理 。包括公网集群终
                端、执法记录仪，专网对讲机、卫星电话等
              </div>
            </div>
            <div
              class="three-item"
              :class="activenum == '1' ? 'active' : ''"
              @mouseenter="mouseEnterNav('1', $event)"
            >
              <p>应急结构化预案</p>
              <div>
                不同类型事件自由配置相应的应急预案，一键绑定对应领导小组、部门等，启动预案按智能分配任务，提高应急响应的效率与及时性。
              </div>
            </div>
          </div>
          <div class="mid">
            <div
              class="three-item"
              :class="activenum == '2' ? 'active' : ''"
              @mouseenter="mouseEnterNav('2', $event)"
            >
              <p>数据流全域治理</p>
              <div>
                构建应急数据资源目录和知识图谱，唤醒沉睡数据，建立大数据分析应用，自动生成分析报告；简单可视化的多人协作管理、开发结构化地构建统计指标体系，打通技术元数据、业务元数据。应急管理的智能监测预警和高效辅助决策提供支持。
              </div>
            </div>
          </div>
          <div class="bottom">
            <div
              class="three-item"
              :class="activenum == '3' ? 'active' : ''"
              @mouseenter="mouseEnterNav('3', $event)"
            >
              <p>多模型算法预警</p>
              <div>
                为应急管理各类应用提供预测、预警类机器学习算法分析服务，发现安全应急潜在的运行规律
              </div>
            </div>
            <div
              class="three-item"
              :class="activenum == '4' ? 'active' : ''"
              @mouseenter="mouseEnterNav('4', $event)"
            >
              <p>多终端融合通信</p>
              <div>
                运用物联网、大数据分析等技术，全方位展示应急事件进展情况为应急工作开展提供强力保障，系统通过应急资源一张图，在GIS地图直观查看资源各类情况。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fourth">
        <div class="execle"></div>
      </div>
    </div>
    <bottombanner></bottombanner>
  </div>
</template>

<script>
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
import topnavigation from "../../components/topnavigation.vue";
import bottombanner from "../../components/bottombanner.vue";
export default {
  components: { topnavigation, bottombanner },
  data() {
    return {
      activenum: "0",
      list: [
        {
          title: "多终端融合通信",
          text: "拥有强大的跨平台能力，具备强大的对接兼容性，可融合多种通 讯手段，对各种通讯手段实现灵活的调度管理。包括公网集群终 端、执法记录仪，专网对讲机、卫星电话等。",
        },
        {
          title: "应急结构化预案",
          text: "不同类型事件自由配置相应的应急预案，一键绑定对应领导小组、 部门等，启动预案按智能分配任务，提高应急响应的效率与及时 性。",
        },
        {
          title: "数据流全域治理",
          text: "构建应急数据资源目录和知识图谱，唤醒沉睡数据，建立大数据分析应用，自动生成分析 报告；简单可视化的多人协作管理、开发结构化地构建统计指标体系，打通技术元数据、 业务元数据。应急管理的智能监测预警和高效辅助决策提供支持。",
        },
        {
          title: "多模型算法预警",
          text: "为应急管理各类应用提供预测、预警类机器学习算法分析服务， 发现安全应急潜在的运行规律。",
        },
        {
          title: "多维度综合展示",
          text: "运用物联网、大数据分析等技术，全方位展示应急事件进展情况 为应急工作开展提供强力保障，系统通过应急资源一张图，在 GIS地图直观查看资源各类情况。",
        },
      ],
    };
  },
  mounted() {
    new Swiper(".swiper-container", {
      direction: "horizontal", // 垂直切换选项
      //mousewheel: true, //滚轮
      autoplay: {
        //自动开始
        delay: 500, //时间间隔
        action: true, //*手动操作轮播图后不会暂停*
        disableOnInteraction: false,
      },
      slidesPerView: 2,
      centeredSlides: false,
      loop:true, // 循环模式选项
    });
  },
  methods: {
    mouseEnterNav(val, e) {
      console.log("123", val, e);
      this.activenum = val;
    },
  },
};
</script>

<style lang="less" scoped>
.yj {
  width: 100%;
  overflow: hidden;
  font-family: PingFang SC;
  font-weight: 500;
  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
  .content {
  }
  .content .first {
    width: 100%;
    height: 1080px;
    // background: url('../assets/home-second.png') no-repeat 100% 100%;
    background: url("../../assets/yj/binner.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .content .sencend {
    width: 100%;
    height: 1080px;
    overflow: hidden;
    .minbox {
      width: 81%;
      height: 814px;
      margin: 124px auto 142px;
    }
    .minbox .sen-top {
      width: 100%;
      height: 283px;
      background: url("../../assets/yj/wordbg1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      overflow: hidden;
      color: #fff;
      .title {
        display: flex;
        // width: 27%;
        // height: 38px;
        // align-items: center;
        flex-wrap: nowrap;
        // margin: 50px auto;
        margin: 53px 0 43px 0;
        justify-content: center;
        img {
          width: 38px;
          //455
          height: 36px;
          margin-right: 30px;
        }
        img:last-child {
          margin-left: 30px;
        }
        div {
          font-size: 32px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 1;
        }
      }
      .word {
        // width: 80%;
        text-align: left;
        // margin: 0  auto;
        margin: 0 55px 0 60px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 30px;
      }
    }
    .minbox .sen-bom {
        .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .swiper-container {
      width: 1600px;
      height: 380px;
      margin-top: 39px;
    }
    .swiper-slide {
      width: 770px !important;
      height: 380px;
      border-radius: 30px;
      background-color: #fff;
      margin-right: 30px;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      p{
        position: absolute;
        bottom: 10px;
        left: 40px;
        z-index: 10;
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 30px;
      }
    }
  }
      // width: 100%;
      // height: 380px;
      // display: flex;
      // justify-content: space-between;
      // .left {
      //   width: 49%;
      //   height: 380px;
      //   background: url("../../assets/yj/left1.png");
      //   background-size: 100% 100%;
      //   background-repeat: no-repeat;
      // }
      // .right {
      //   width: 49%;
      //   height: 380px;
      //   background: url("../../assets/yj/right1.png");
      //   background-size: 100% 100%;
      //   background-repeat: no-repeat;
      // }
      // p {
      //   text-align: left;
      //   font-size: 32px;
      //   font-family: PingFang SC;
      //   font-weight: bold;
      //   color: #fff;
      //   margin-top: 311px;
      //   margin-left: 8%;
      // }
    }
  }
  .content .threeth {
    width: 100%;
    height: 1080px;
    // background: url('../assets/home-second.png') no-repeat 100% 100%;
    background: url("../../assets/yj/sencendbg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    padding-bottom: 72px;
    .title {
      display: flex;
      margin: 84px 0 70px 0;
      justify-content: center;

      img {
        width: 38px;
        height: 36px;
        margin-right: 30px;
      }
      img:last-child {
        margin-left: 30px;
      }
      div {
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 1;
      }
    }
    .list-box {
      // width: 90%;
      height: 834px;
      // margin: 0 auto;
      text-align: left;
      p {
        margin: 0;
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: bold;
      }
    }
    .list-box .top {
      width: 100%;
      display: flex;
      // height: 275px;
      flex-wrap: nowrap;
      margin-left: 160px;
      .three-item {
        // margin: 0 5%;
        margin-right: 48px;
        border-radius: 20px;
        background: #ffffff;
        padding: 40px;
        height: 172px;
        border: 1px solid #1c7dff;
        color: #333333;
        div {
          width: 680px;
          margin-top: 21px;
          font-size: 24px;
        }
      }
      .active {
        background: url("../../assets/yj/active.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        color: #fff;
        border: 1px solid transparent;
      }
    }
    .list-box .mid {
      width: 100%;
      display: flex;
      // height: 275px;
      flex-wrap: nowrap;
      height: 275px;
      // margin-left: 437px;
      justify-content: center;
      margin: 30px 0;
      .three-item {
        padding: 50px 50px 53px 40px;
        height: 172px;
        border: 1px solid #1c7dff;
        border-radius: 20px;
        background: #ffffff;
        div {
          width: 934px;
          margin-top: 21px;
          font-size: 24px;
        }
      }
      .active {
        background: url("../../assets/yj/active.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border: 1px solid transparent;
        color: #fff;
      }
    }
    .list-box .bottom {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      margin-left: 160px;
      .three-item {
        padding: 51px 58px 83px 42px;
        height: 172px;
        border-radius: 20px;
        border: 1px solid #1c7dff;
        background: #ffffff;
        margin-right: 50px;
        div {
          margin-top: 21px;
          width: 660px;
          font-size: 24px;
        }
      }
      .active {
        background: url("../../assets/yj/active.png");
        background-size: 100% 100%;
        border: 1px solid transparent;
        background-repeat: no-repeat;
        color: #fff;
      }
    }
  }
  .content .fourth {
    width: 100%;
    height: 1080px;
    // background: url('../assets/home-second.png') no-repeat 100% 100%;
    background: #f6faff;
    padding: 23px 0 32px 159px;
    .execle {
      width: 83%;
      height: 1022px;
      // margin: 26px auto;
      background: url("../../assets/yj/execel.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}
</style>
